<template>
  <div class="w-full">
    <div
      v-if="forgotPasswd == false"
      class="min-h-screen flex flex-col items-center justify-center bg-gray-300"
    >
      <div
        class="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md"
      >
        <div class="login-img my-4">
          <img :src="image" alt="codservice" />
        </div>

        <div
          class="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800"
        >
          {{ $t("login_account") }}
        </div>

        <div class="relative mt-5 h-px bg-gray-300">
          <div class="absolute left-0 top-0 flex justify-center w-full -mt-2">
            <span class="bg-white px-4 text-xs text-gray-500 uppercase">{{
              $t("login_with_email_username")
            }}</span>
          </div>
        </div>
        <div class="mt-10">
          <div>
            <div class="flex flex-col mb-6">
              <label
                for="email"
                class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                >{{ $t("email_address") }}:</label
              >
              <div class="relative">
                <div
                  class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
                >
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                </div>

                <input
                  type="text"
                  name="username"
                  required
                  class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500"
                  v-model="formData.username"
                  :placeholder="$t('username_or_email')"
                />
              </div>
            </div>

            <div class="flex flex-col mb-6">
              <label
                for="password"
                class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                >{{ $t("password") }}:</label
              >
              <div class="relative">
                <div
                  class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
                >
                  <span>
                    <svg
                      class="h-6 w-6"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                  </span>
                </div>

                <input
                  type="password"
                  name="password"
                  @keyup.enter="trigger"
                  v-model="formData.password"
                  class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500"
                  :placeholder="$t('password')"
                />
              </div>
            </div>

            <div class="flex items-center mb-4 -mt-4">
              <div class="flex ml-auto">
                <button
                  @click="OpenPopUp"
                  class="inline-flex items-center font-bold text-green-500 hover:text-green-600 text-xs text-center focus:outline-none"
                >
                  <span class="ml-2">{{ $t("forgot_password") }}</span>
                </button>
              </div>
            </div>

            <div class="flex w-full">
              <button
                @click="login"
                ref="sendReply"
                class="flex relative loidingconfirm items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-green-700 hover:bg-green-700 rounded py-2 w-full transition duration-150 ease-in"
                :disabled="loading"
              >
                <loading
                  :active.sync="loading"
                  :is-full-page="fullPage"
                ></loading>
                <span class="mr-2 uppercase">{{ $t("login") }}</span>
                <span>
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center mt-6">
          <button
            @click="landingPage"
            target="_blank"
            class="inline-flex items-center font-bold text-green-500 hover:text-green-600 text-xs text-center focus:outline-none"
          >
            <span>
              <svg
                class="h-6 w-6"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </span>
            <span class="ml-2">{{ $t("dont_have_account") }}</span>
          </button>
        </div>
        <vue-modal-2
          name="myModal"
          :headerOptions="{ title: 'Find your email address' }"
          :noFooter="true"
          modalSize="lg"
          @on-close="closePopUp('myModal')"
        >
          <div v-if="valideUser == false" class="modal-footer px-5 border0-t">
            <div class="items-center justify-center flex rounded-full">
              <span
                class="w-1/4 text-sm text-white inline-flex items-center justify-center"
              >
                <img
                  alt="..."
                  class="w-full align-middle border p-3 rounded-full"
                  src="../assets/logo.jpg"
                />
              </span>
            </div>
            <div class="flex flex-col mb-6">
              <div class="mt-10 relative">
                <div
                  class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
                >
                  <span class="material-icons">email</span>
                </div>

                <input
                  type="text"
                  name="emailAddress"
                  class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500"
                  v-model="formData.forgotPasswdEmail"
                  :placeholder="$t('enter_email_address')"
                />
              </div>
            </div>

            <button
              @click="closePopUp('myModal')"
              class="flex mt-6 mb-2 text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right"
            >
              {{ $t("cancel") }}
            </button>
            <button
              @click="forgotPasswordUser"
              class="flex mt-6 mb-2 mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right"
            >
              {{ $t("send_verification_code") }}
            </button>
          </div>

          <div v-else class="modal-footer px-5 border0-t">
            <div class="items-center justify-center flex rounded-full">
              <span
                class="w-1/4 text-sm text-white inline-flex it ems-center justify-center"
              >
                <img
                  alt="..."
                  class="w-full align-middle border p-3 rounded-full"
                  src="../assets/logo.jpg"
                />
              </span>
            </div>
            <div class="flex flex-col mb-6">
              <div class="mt-2 relative">
                <div
                  class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
                >
                  <span class="material-icons">lock_outline</span>
                </div>
                <input
                  type="text"
                  name="verification code"
                  class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500"
                  v-model="formData.verify.code"
                  :placeholder="$t('enter_code')"
                />
              </div>
            </div>

            <button
              @click="closePopUp('myModal')"
              class="flex mt-6 mb-2 text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right"
            >
              {{ $t("cancel") }}
            </button>
            <button
              @click="validateVerificationCode"
              :disabled="loadingVerify"
              class="flex mt-6 mb-2 mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm"
            >
              <loading
                :active.sync="loadingVerify"
                :is-full-page="fullPage"
              ></loading>
              {{ $t("confirm") }}
            </button>
          </div>
        </vue-modal-2>

        <vue-modal-2
          name="ResetPassword"
          :headerOptions="{ title: 'Reset password' }"
          :noFooter="true"
          modalSize="md"
          @on-close="closePopUp('ResetPassword')"
        >
          <div class="flex flex-col items-center justify-center">
            <div
              class="flex flex-col bg-white px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md"
            >
              <div class="login-img">
                <img :src="image" alt="codservice" />
              </div>

              <div
                class="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800"
              >
                {{ $t("Login to your account ") }}
              </div>

              <div class="mt-10">
                <div>
                  <div class="flex flex-col mb-6">
                    <label
                      for="email"
                      class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >{{ $t("New Password") }}:</label
                    >
                    <div class="relative">
                      <div
                        class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
                      >
                        <svg
                          class="h-6 w-6"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                          />
                        </svg>
                      </div>

                      <input
                        type="password"
                        name="username"
                        required
                        class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500"
                        v-model="formData.password"
                        :placeholder="$t('New password')"
                      />
                    </div>
                  </div>

                  <div class="flex flex-col mb-6">
                    <label
                      for="password"
                      class="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >{{ $t("Confirm Password") }}:</label
                    >
                    <div class="relative">
                      <div
                        class="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
                      >
                        <span>
                          <svg
                            class="h-6 w-6"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                          </svg>
                        </span>
                      </div>

                      <input
                        type="password"
                        name="password"
                        required
                        v-model="formData.confirmPassword"
                        class="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500"
                        :placeholder="$t('Confirm password')"
                      />
                    </div>
                  </div>

                  <div class="flex w-full">
                    <button
                      @click="newPassword"
                      class="flex relative loidingconfirm items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-green-700 hover:bg-green-700 rounded py-2 w-full transition duration-150 ease-in"
                      :disabled="loadingVerify"
                    >
                      <loading
                        :active.sync="loadingVerify"
                        :is-full-page="fullPage"
                      ></loading>
                      <span class="mr-2 uppercase">{{ $t("Continue") }}</span>
                      <span>
                        <svg
                          class="h-6 w-6"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-modal-2>
      </div>
    </div>
  </div>
</template>

<script>
import image from "@/assets/codservice.png";
export default {
  props: {
    loginUser: { type: Function },
  },
  mounted() {},
  data() {
    return {
      formData: {
        forgotPasswdEmail: "",
        verify: {
          code: "",
        },
      },
      image: image,
      forgotPasswd: false,
      valideUser: false,
      user: {},
      loading: false,
      loadingVerify: false,
      fullPage: false,
    };
  },
  methods: {
    getAccess(modelToCheck, accessToCheck) {
      const permissions = this.user.role.permissions;
      for (let i in permissions) {
        let model = permissions[i]["model"];
        let access = permissions[i]["access"];

        if (model == modelToCheck) {
          return access[accessToCheck];
        }
      }
    },
    async newPassword() {
      if (this.formData.password)
        this.formData.password = this.formData.password.trim();
      if (this.formData.confirmPassword)
        this.formData.confirmPassword = this.formData.confirmPassword.trim();

      if (
        this.formData.password &&
        this.formData.confirmPassword &&
        this.formData.forgotPasswdEmail &&
        this.formData.verify.code &&
        this.formData.password == this.formData.confirmPassword
      ) {
        const data = {
          email: this.formData.forgotPasswdEmail,
          password: this.formData.password,
          code: this.formData.verify.code,
          newPassword: true,
        };
        this.loadingVerify = true;
        const resultat = await this.$server.verificationCode("users", data);
        this.loadingVerify = false;

        if (resultat && resultat.status == 200) {
          this.$vm2.close("ResetPassword");
        } else {
          alert(resultat.content, "warning");
        }
      } else {
        alert("Confirmation password does not match", "warning");
        return false;
      }
    },
    async forgotPasswordUser() {
      const data = {
        email: this.formData.forgotPasswdEmail.toLowerCase().trim(),
      };
      this.loadingVerify = true;
      const res = await this.$server.forgotPassword("users", data);
      this.loadingVerify = false;
      if (res && res.content._id) {
        this.valideUser = true;
        this.user = res.content;
      } else {
        this.$vm2.close("myModal");
        alert(this.$t("email_address_incorrect"), "warning");
        this.forgotPasswdEmail = "";
        this.user = {};
      }
    },
    trigger() {
      this.$refs.sendReply.click();
    },
    getDomaine(link) {
      let domain = window.location.hostname;
      let protocol = window.location.protocol;
      if (domain == "localhost") domain = "localhost:8081";

      window.location.href = "/" + link;
      // this.$router.push("/"+link).catch(()=>{});
    },
    async validateVerificationCode() {
      const data = {
        email: this.formData.forgotPasswdEmail.toLowerCase().trim(),
        verify: {
          code: this.formData.verify.code,
        },
      };
      this.loadingVerify = true;
      const resultat = await this.$server.verificationCode("users", data);
      this.loadingVerify = false;

      // return false
      if (resultat && resultat.status == 200) {
        this.valideUser = false;
        this.$vm2.close("myModal");
        this.$vm2.open("ResetPassword");
        //  let self = this;
        // const token = resultat.headers["X-Auth-Token"];
        // this.$jwtService.saveToken(token);
        // this.valideUser = false;
        //  this.$vm2.close("myModal");
        // alert(this.$t('valide_code'), "success");
        // setTimeout(function() {self.getDomaine('profil');}, 250);
      } else {
        // this.$vm2.close("myModal");
        //this.valideUser = false;
        alert(resultat.content, "warning");
      }
    },
    async login() {
      if (!this.formData.username || !this.formData.password) {
        alert("l'email ou le mot de passe est vide", "warning");
        return false;
      }
      const data = {
        email: this.formData.username.toLowerCase().trim(),
        username: this.formData.username.trim(),
        password: this.formData.password.trim(),
      };
      this.loading = true;
      const res = await this.$server.login("users", data);
      this.loading = false;

      if (res.content._id) {
        if (
          res.content.status == "deleted" ||
          res.content.status == "blocked"
        ) {
          alert(this.$t("not_access_account"), "warning");
        } else {
          let self = this;
          if (res.content.status == "active") {
            const token = res.headers["X-Auth-Token"];
            this.$jwtService.saveToken(token);
            if (
              res.content.type == "StockManager" ||
              res.content.type == "Courier" ||
              res.content.type == "Return"
            )
              setTimeout(function () {
                self.getDomaine("shippings");
              }, 250);
            else if (
              res.content.type == "TeleConsultant" ||
              res.content.type == "NRP" ||
              res.content.type == "CancelledOrder" ||
              res.content.type == "ToRemind"
            )
              setTimeout(function () {
                self.getDomaine("orders/confirmation");
              }, 250);
            else if (res.content.type == "Accountant")
              setTimeout(function () {
                self.getDomaine("payments");
              }, 250);
            else {
              if (
                res.content.type != "Seller" &&
                res.content.role.permissions.find(
                  (o) => o.access.update == true && o.model != "categories"
                )
              )
                setTimeout(function () {
                  self.getDomaine(
                    res.content.role.permissions.find(
                      (o) => o.access.update == true && o.model != "categories"
                    ).model
                  );
                }, 250);
              else {
                this.$router.push("home");
                location.reload();
              }
            }
          } else {
            const token = res.headers["X-Auth-Token"];
            this.$jwtService.saveToken(token);
            setTimeout(function () {
              self.getDomaine("profil");
            }, 250);
          }
        }
      } else {
        alert(this.$t("username_pass_incorrect"), "warning");
      }
    },
    landingPage() {
      //location.href = "https://CodInAfric.ma/";
    },
    async OpenPopUp() {
      this.$vm2.open("myModal");
    },
    closePopUp(name) {
      this.$vm2.close(name);
      if (name == "myModal") this.valideUser = false;
    },
  },
};
</script>

<style scoped>
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}
dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.login-img {
   height: auto;
  width: 157px;
  margin: 0 auto 10px;
  display: block;
  box-sizing: border-box;
  text-align: center;
}
</style>
