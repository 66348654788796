var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_vm.forgotPasswd == false ? _c('div', {
    staticClass: "min-h-screen flex flex-col items-center justify-center bg-gray-300"
  }, [_c('div', {
    staticClass: "flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md"
  }, [_c('div', {
    staticClass: "login-img my-4"
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "alt": "codservice"
    }
  })]), _c('div', {
    staticClass: "font-medium self-center text-xl sm:text-2xl uppercase text-gray-800"
  }, [_vm._v(" " + _vm._s(_vm.$t("login_account")) + " ")]), _c('div', {
    staticClass: "relative mt-5 h-px bg-gray-300"
  }, [_c('div', {
    staticClass: "absolute left-0 top-0 flex justify-center w-full -mt-2"
  }, [_c('span', {
    staticClass: "bg-white px-4 text-xs text-gray-500 uppercase"
  }, [_vm._v(_vm._s(_vm.$t("login_with_email_username")))])])]), _c('div', {
    staticClass: "mt-10"
  }, [_c('div', [_c('div', {
    staticClass: "flex flex-col mb-6"
  }, [_c('label', {
    staticClass: "mb-1 text-xs sm:text-sm tracking-wide text-gray-600",
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("email_address")) + ":")]), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
  }, [_c('svg', {
    staticClass: "h-6 w-6",
    attrs: {
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.username,
      expression: "formData.username"
    }],
    staticClass: "text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500",
    attrs: {
      "type": "text",
      "name": "username",
      "required": "",
      "placeholder": _vm.$t('username_or_email')
    },
    domProps: {
      "value": _vm.formData.username
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "username", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "flex flex-col mb-6"
  }, [_c('label', {
    staticClass: "mb-1 text-xs sm:text-sm tracking-wide text-gray-600",
    attrs: {
      "for": "password"
    }
  }, [_vm._v(_vm._s(_vm.$t("password")) + ":")]), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
  }, [_c('span', [_c('svg', {
    staticClass: "h-6 w-6",
    attrs: {
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
    }
  })])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.password,
      expression: "formData.password"
    }],
    staticClass: "text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500",
    attrs: {
      "type": "password",
      "name": "password",
      "placeholder": _vm.$t('password')
    },
    domProps: {
      "value": _vm.formData.password
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.trigger.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "password", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "flex items-center mb-4 -mt-4"
  }, [_c('div', {
    staticClass: "flex ml-auto"
  }, [_c('button', {
    staticClass: "inline-flex items-center font-bold text-green-500 hover:text-green-600 text-xs text-center focus:outline-none",
    on: {
      "click": _vm.OpenPopUp
    }
  }, [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.$t("forgot_password")))])])])]), _c('div', {
    staticClass: "flex w-full"
  }, [_c('button', {
    ref: "sendReply",
    staticClass: "flex relative loidingconfirm items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-green-700 hover:bg-green-700 rounded py-2 w-full transition duration-150 ease-in",
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.login
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('span', {
    staticClass: "mr-2 uppercase"
  }, [_vm._v(_vm._s(_vm.$t("login")))]), _c('span', [_c('svg', {
    staticClass: "h-6 w-6",
    attrs: {
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
    }
  })])])], 1)])])]), _c('div', {
    staticClass: "flex justify-center items-center mt-6"
  }, [_c('button', {
    staticClass: "inline-flex items-center font-bold text-green-500 hover:text-green-600 text-xs text-center focus:outline-none",
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": _vm.landingPage
    }
  }, [_c('span', [_c('svg', {
    staticClass: "h-6 w-6",
    attrs: {
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
    }
  })])]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.$t("dont_have_account")))])])]), _c('vue-modal-2', {
    attrs: {
      "name": "myModal",
      "headerOptions": {
        title: 'Find your email address'
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.closePopUp('myModal');
      }
    }
  }, [_vm.valideUser == false ? _c('div', {
    staticClass: "modal-footer px-5 border0-t"
  }, [_c('div', {
    staticClass: "items-center justify-center flex rounded-full"
  }, [_c('span', {
    staticClass: "w-1/4 text-sm text-white inline-flex items-center justify-center"
  }, [_c('img', {
    staticClass: "w-full align-middle border p-3 rounded-full",
    attrs: {
      "alt": "...",
      "src": require("../assets/logo.jpg")
    }
  })])]), _c('div', {
    staticClass: "flex flex-col mb-6"
  }, [_c('div', {
    staticClass: "mt-10 relative"
  }, [_c('div', {
    staticClass: "inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
  }, [_c('span', {
    staticClass: "material-icons"
  }, [_vm._v("email")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.forgotPasswdEmail,
      expression: "formData.forgotPasswdEmail"
    }],
    staticClass: "text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500",
    attrs: {
      "type": "text",
      "name": "emailAddress",
      "placeholder": _vm.$t('enter_email_address')
    },
    domProps: {
      "value": _vm.formData.forgotPasswdEmail
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "forgotPasswdEmail", $event.target.value);
      }
    }
  })])]), _c('button', {
    staticClass: "flex mt-6 mb-2 text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right",
    on: {
      "click": function click($event) {
        return _vm.closePopUp('myModal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "flex mt-6 mb-2 mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right",
    on: {
      "click": _vm.forgotPasswordUser
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("send_verification_code")) + " ")])]) : _c('div', {
    staticClass: "modal-footer px-5 border0-t"
  }, [_c('div', {
    staticClass: "items-center justify-center flex rounded-full"
  }, [_c('span', {
    staticClass: "w-1/4 text-sm text-white inline-flex it ems-center justify-center"
  }, [_c('img', {
    staticClass: "w-full align-middle border p-3 rounded-full",
    attrs: {
      "alt": "...",
      "src": require("../assets/logo.jpg")
    }
  })])]), _c('div', {
    staticClass: "flex flex-col mb-6"
  }, [_c('div', {
    staticClass: "mt-2 relative"
  }, [_c('div', {
    staticClass: "inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
  }, [_c('span', {
    staticClass: "material-icons"
  }, [_vm._v("lock_outline")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.verify.code,
      expression: "formData.verify.code"
    }],
    staticClass: "text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500",
    attrs: {
      "type": "text",
      "name": "verification code",
      "placeholder": _vm.$t('enter_code')
    },
    domProps: {
      "value": _vm.formData.verify.code
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData.verify, "code", $event.target.value);
      }
    }
  })])]), _c('button', {
    staticClass: "flex mt-6 mb-2 text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right",
    on: {
      "click": function click($event) {
        return _vm.closePopUp('myModal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "flex mt-6 mb-2 mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm",
    attrs: {
      "disabled": _vm.loadingVerify
    },
    on: {
      "click": _vm.validateVerificationCode
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingVerify,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingVerify = $event;
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")], 1)])]), _c('vue-modal-2', {
    attrs: {
      "name": "ResetPassword",
      "headerOptions": {
        title: 'Reset password'
      },
      "noFooter": true,
      "modalSize": "md"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.closePopUp('ResetPassword');
      }
    }
  }, [_c('div', {
    staticClass: "flex flex-col items-center justify-center"
  }, [_c('div', {
    staticClass: "flex flex-col bg-white px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md"
  }, [_c('div', {
    staticClass: "login-img"
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "alt": "codservice"
    }
  })]), _c('div', {
    staticClass: "font-medium self-center text-xl sm:text-2xl uppercase text-gray-800"
  }, [_vm._v(" " + _vm._s(_vm.$t("Login to your account ")) + " ")]), _c('div', {
    staticClass: "mt-10"
  }, [_c('div', [_c('div', {
    staticClass: "flex flex-col mb-6"
  }, [_c('label', {
    staticClass: "mb-1 text-xs sm:text-sm tracking-wide text-gray-600",
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("New Password")) + ":")]), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
  }, [_c('svg', {
    staticClass: "h-6 w-6",
    attrs: {
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.password,
      expression: "formData.password"
    }],
    staticClass: "text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500",
    attrs: {
      "type": "password",
      "name": "username",
      "required": "",
      "placeholder": _vm.$t('New password')
    },
    domProps: {
      "value": _vm.formData.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "password", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "flex flex-col mb-6"
  }, [_c('label', {
    staticClass: "mb-1 text-xs sm:text-sm tracking-wide text-gray-600",
    attrs: {
      "for": "password"
    }
  }, [_vm._v(_vm._s(_vm.$t("Confirm Password")) + ":")]), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
  }, [_c('span', [_c('svg', {
    staticClass: "h-6 w-6",
    attrs: {
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
    }
  })])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.confirmPassword,
      expression: "formData.confirmPassword"
    }],
    staticClass: "text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-green-500",
    attrs: {
      "type": "password",
      "name": "password",
      "required": "",
      "placeholder": _vm.$t('Confirm password')
    },
    domProps: {
      "value": _vm.formData.confirmPassword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "confirmPassword", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "flex w-full"
  }, [_c('button', {
    staticClass: "flex relative loidingconfirm items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-green-700 hover:bg-green-700 rounded py-2 w-full transition duration-150 ease-in",
    attrs: {
      "disabled": _vm.loadingVerify
    },
    on: {
      "click": _vm.newPassword
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingVerify,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingVerify = $event;
      }
    }
  }), _c('span', {
    staticClass: "mr-2 uppercase"
  }, [_vm._v(_vm._s(_vm.$t("Continue")))]), _c('span', [_c('svg', {
    staticClass: "h-6 w-6",
    attrs: {
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
    }
  })])])], 1)])])])])])])], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }